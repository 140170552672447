import { sucessMessage, errorMessage } from "../../../../components/MessageBus";

/**
 * Copia uma URL de rota do Google Maps para a área de transferência.
 *
 * @param {Array|Object} coordinates - Pode ser:
 *  1. Uma única coordenada: [lat, lng]
 *  2. Um array de coordenadas simples: [[lat1, lng1], [lat2, lng2]]
 *  3. Um array contendo objetos com listas de coordenadas: 
 *     [{ coordinates: [[lat1, lng1], [lat2, lng2]] }, ...]
 */
export function copyCoordinatesGoogle(coordinates) {
  if (!coordinates || coordinates.length === 0) {
    errorMessage("Coordenadas não disponíveis para copiar.");
    return;
  }

  const baseUrl = "https://www.google.com/maps/dir/";

  let partes;

  // Determina o tipo de entrada e processa as coordenadas
  if (Array.isArray(coordinates[0])) {
    // Caso seja uma lista de coordenadas simples [[lat, lng], ...]
    partes = coordinates.map((coordenada) => coordenada.slice().reverse().join(","));
  } else if (coordinates[0]?.coordinates) {
    // Caso seja uma estrutura [{ coordinates: [[lat, lng], ...] }, ...]
    partes = coordinates.map((linha) =>
      linha.coordinates.map((coordenada) => coordenada.slice().reverse().join(",")).join("/")
    );
  } else if (Array.isArray(coordinates)) {
    // Caso seja uma única coordenada [lat, lng]
    partes = [coordinates.slice().reverse().join(",")];
  } else {
    errorMessage("Formato de coordenadas inválido.");
    return;
  }

  const urlCompleta = baseUrl + partes.join("/");

  // Copia a URL para a área de transferência
  navigator.clipboard
    .writeText(urlCompleta)
    .then(() => {
      sucessMessage("URL da rota foi copiada para a área de transferência");
    })
    .catch((erro) => {
      errorMessage("Não foi possível copiar a URL de rota.");
      console.error(erro);
    });
}
