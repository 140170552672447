<template>
  <div id="ConnectedEquipment">
    <div class="d-flex justify-content-end mb-2">
      <b-button
        id="btnAllCables"
        size="sm"
        variant="success" 
        class="mr-2"
        title="Exibir todos" 
        :disabled="!list.length"
        @click.prevent="showAllCables()">
        Ver todos os cabos
      </b-button>
      <b-button
        id="btnExport"
        size="sm"
        variant="success" 
        title="Exportar" 
        :disabled="!list.length"
        @click.prevent="print()">
        Exportar
      </b-button>
    </div>
    
    <b-table 
      empty-text="Não existem registros para serem apresentados na lista."
      :items="list"
      :fields="filteredFields" 
      responsive
      show-empty 
      small 
      striped>

      <template #cell(type)="row">
        {{ translateType(row.item.eqpType) }}
      </template>

      <template #cell(name)="row">
        {{ row.item.eqpName }}
      </template>

      <template #cell(connection1)="row">
        <div>{{ row.item.internalEqpName1 || row.item.cableName || '-' }}</div>
        <div class="secondary">{{ row.item.doorName1 || `Fibra ${row.item.fiberName1}` || '-' }}</div>
      </template>

      <template #cell(connection2)="row">
        <div>{{ row.item.internalEqpName2 || row.item.cableName || '-' }}</div>
        <div class="secondary">{{ row.item.doorName2 || `Fibra ${row.item.fiberName2}` || '-' }}</div>
      </template>

      <template #cell(cableSize)="row">
        {{ row.item.cableSize ? row.item.cableSize + ' m' : '-' }}
      </template>

      <template #cell(location)="row">
        <b-button 
          size="sm"
          variant="warning" 
          @click.prevent="showCableMap(row.item)"
        >  
            <i class="fa fa-map-marker"></i>  
        </b-button>
      </template>
      
    </b-table>
  </div>
</template>
  
<script>
import TabsBase from "./TabsBase";
import { findEqpsConnectedPath } from "../../../../router/ApiPaths";
import BaseRequest from "../../../../request/request";
import { sendMapAction, addLineMap } from "../../../../components/MessageBus";
import PrintDiagram from "../components/PrintDiagram";
import { minimizeAll } from "../../../../components/extra/HideModals";

export default {
  name: "ConnectedEquipment",
  extends: TabsBase,
  mixins: [BaseRequest],
  props: {
    equipmentId: Number,
    folderId: Number,
    equipmentType: String,
  },
  data() {
    return {
      list: []
    };
  },
  computed: {
    baseFields() {
      let fields = [
        { key: "type", label: "Tipo" },
        { key: "name", label: "Nome" }
      ];

      return fields;
    },
    connectionFields() {
      return [
        { key: "connection1", label: "Lado A" },
        { key: "connection2", label: "Lado B" }
      ];
    },
    extraFields() {
      return [
        { key: "cableSize", label: "Tamanho total" },
        { key: "location", label: "Localização", class: "text-right width-100" }
      ];
    },
    filteredFields() {
      return [...this.baseFields, ...this.connectionFields, ...this.extraFields];
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      let path = findEqpsConnectedPath(this.equipmentId, this.equipmentType);

      this.baseRequest("get", path).then((data) => {
        this.list = data;
      });
    },
    showCableMap(entity, dontFit) {
      sendMapAction(addLineMap, {
        coordinates: entity.cableGeometry.coordinates,
        clear: false,
        dontFit
      });
      minimizeAll();
    },
    showAllCables() {
      this.list.forEach((item, idx) => {
        this.showCableMap(item, idx + 1 === this.list.length);
      });
    },
    print() {
      PrintDiagram("ConnectedEquipment", "CTO - Clientes conectados", ["btnAllCables", "btnExport"]);
    },
    translateType(type) {
      const typeMap = { S: "Cliente", C: "CEO", O: "CTO" };
      return typeMap[type] || type;
    }
  }
};
</script>

<style scoped>
.connection-cell {
  display: flex;
  flex-direction: column;
}

.secondary {
  font-size: 0.9em;
  color: #666;
}
</style>