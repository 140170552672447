<template>
  <div>
    <b-table
      empty-text="Não existe registros para serem apresentados na lista."
      :items="filteredItems"
      :fields="fields"
      responsive
      show-empty
      small
      striped
    >
      <template #cell(tube)="row">
        <p class="m-0">
          <small>
            Tubo loose:
            <b-badge
              class="fiber-text-color"
              :style="{ backgroundColor: row.item.color }"
              >{{ row.item.tube }}</b-badge
            >
          </small>
        </p>
        <p class="m-0">
          <small>
            Fibra:
            {{ row.item.name }}
          </small>
        </p>
      </template>

      <template #cell(name)="row">
        <label size="sm" variant="muted" v-if="row.item.damaged">
          {{ row.item.name }}
        </label>
        <span class="p-2" style="color: #ff0000" v-if="row.item.damaged"
          >Danificada</span
        >
      </template>

      <template #cell(numberBoards)="row">
        <b-button
          :id="'btnOpenDamagedFiber' + row.item"
          title="Fibra danificada"
          @click.prevent="openDamagedFiber(row.item)"
          v-has-permission.permission="['FIB', 'VI']"
          size="sm"
          variant="warning"
        >
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        </b-button>

        <b-button
          v-if="row.item.damaged"
          :id="'btnRemoveDamagedFiber' + row.item.id"
          title="Remover indicação de fibra danificada"
          @click.prevent="removeDamagedFiber(row.item.id, index)"
          v-has-permission.permission="['FIB', 'EX']"
          size="sm"
          variant="danger"
        >
          <i class="fa fa-trash" aria-hidden="true"></i>
        </b-button>
      </template>

      <template #cell(shortRoute)="row">
        <b-button
          v-if="row.item.routeId"
          :id="'btnOpenDiagramResumed' + row.item.id"
          title="Diagrama da rota resumida"
          @click.prevent="openDiagramResumed(row.item.id)"
          size="sm"
          variant="light"
        >
          <i
            class="fa fa-sitemap"
            style="color: #007bff"
            aria-hidden="true"
          ></i>
        </b-button>
      </template>

      <template #cell(completeRoute)="row">
        <b-button
          v-if="row.item.routeId"
          :id="'btnOpenDiagram' + row.item.id"
          title="Diagrama da rota completa"
          @click.prevent="openDiagram(row.item.id)"
          v-has-permission.permission="['FIB', 'VI']"
          size="sm"
          variant="light"
        >
          <i
            class="fa fa-sitemap"
            style="color: #007bff"
            aria-hidden="true"
          ></i>
        </b-button>
      </template>
      <template #cell(map)="row">
        <b-button
          v-if="row.item.routeId && category != 'I'"
          :id="'btnShowMap' + row.item.id"
          title="Exibir rota no mapa"
          @click.prevent="showMap(row.item.id)"
          v-has-permission.permission="['FIB', 'VI']"
          size="sm"
          variant="light"
        >
          <i
            class="fa fa-map-marker fa-lg"
            style="color: #dc3545"
            aria-hidden="true"
          ></i>
        </b-button>

        <b-button
          v-if="row.item.routeId && category != 'I'"
          variant="light"
          size="sm"
          @click.prevent="copyPosition(row.item.id)"
          title="Copiar a rota do elemento no formato Google"
        >
          <i class="fa fa-globe fa-lg" aria-hidden="true" variant="black"></i>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  fiberCablePath,
  cablesRoutePath,
  damagedFiberPath,
} from "../../../..//router/ApiPaths";
import { openSubPopup } from "../index";
import {
  sendMapAction,
  addLineMap,
  getAction,
} from "../../../../components/MessageBus";
import { minimizeAll } from "../../../../components/extra/HideModals";
import BaseRequest from "../../../../request/request";
import ConfirmDialog from "../../../../components/extra/ConfirmDialog";
import { copyCoordinatesGoogle } from "../../map/functions/copyCoordinatesGoogle";

export default {
  name: "FiberTab",
  mixins: [BaseRequest, ConfirmDialog],
  props: ["equipmentId", "folderId", "category"],
  data() {
    return {
      list: [],
      damagedFiber: [],
      fields: [],
      coordinates: {},
    };
  },
  mounted() {
    this.load();
    this.fields = [
      {
        key: "tube",
        label: "Tubo looses/Cor",
        class: "text-center",
      },
      // {
      //   key: "name",
      //   label: "Fibra",
      // },
      {
        key: "numberBoards",
        label: "Fibra Danificada",
        class: "text-center",
      },
      {
        key: "shortRoute",
        label: "Rota resumida",
        class: "text-center",
      },
      {
        key: "completeRoute",
        label: "Rota completa",
        class: "text-center",
      },
      {
        key: "map",
        label: "Mapa",
        class: "text-center",
        tdClass: "width-100",
      },
    ];
  },
  methods: {
    load(force) {
      if (this.list.length < 1 || force) {
        this.get(fiberCablePath(this.folderId, this.equipmentId)).then(
          (data) => {
            this.list = data;
          }
        );
      }
    },
    openDiagramResumed(fiberId) {
      openSubPopup(4, null, fiberId);
    },
    openDiagram(fiberId) {
      openSubPopup(5, null, fiberId, null, null, null, this.folderId);
    },
    openDamagedFiber(fiber) {
      openSubPopup(17, null, null, fiber);
      getAction((result) => {
        if (result) {
          this.load(true);
        }
      });
    },
    removeDamagedFiber(fiberId) {
      this.$confirmDialog.open("Deseja mesmo excluir ?", () => {
        this.baseRequest("delete", damagedFiberPath(fiberId)).then(() => {
          this.load(true);
        });
      });
    },

    async showMap(fiberId, showScreen) {
      try {
        const data = await this.get(cablesRoutePath(fiberId));

        data.forEach((element, index, array) => {
          if (!showScreen) {
            if (element.geometry && element.geometry.coordinates) {
              sendMapAction(addLineMap, {
                text: `${element.size} m`,
                coordinates: element.geometry.coordinates,
                dontFit: index !== array.length - 1,
              });
            }
          }
        });

        if (!this.coordinates[fiberId]) {
          this.coordinates[fiberId] = [];
        }

        const filteredData = data.filter(
          (e) =>
            e.geometry &&
            e.geometry.coordinates &&
            e.geometry.coordinates.length > 0
        );

        this.coordinates[fiberId].push(...filteredData.map((e) => e.geometry));

        if (!showScreen) {
          minimizeAll();
        }
      } catch (error) {
        console.error("Error fetching cable route data:", error);
      }
    },

    async copyPosition(fiberId) {
      if (!this.coordinates || !this.coordinates[fiberId]) {
        await this.showMap(fiberId, true);
      }

      const vertices = this.coordinates[fiberId];
      copyCoordinatesGoogle(vertices);
    },
  },
  computed: {
    filteredItems() {
      return this.list.filter((item) => {
        if (this.search) {
          return (
            item.name &&
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        } else {
          return item;
        }
      });
    },
  },
};
</script>

<style>
.fiber-text-color {
  color: black;
}
</style>
