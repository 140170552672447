const Viability = () => import("./ViabilityMap");
const Image = () => import("./Image");
const ImageUpload = () => import("./ImageUpload");
const Comment = () => import("./Comment");
const CommentInput = () => import("./CommentInput");
const MessageList = () => import("./MessageList");
const PrintMap = () => import("./PrintMap");
const FolderList = () => import("./FolderList");
const FolderInput = () => import("./FolderInput");
const FolderReport = () => import("./FolderReport");
const FolderEquipamentsReport = () => import("./FolderEquipamentsReport");
const FolderUserList = () => import("./FolderUserList");
const FolderChange = () => import("./FolderChange");
const CableClient = () => import("./CableClient");
const SplitterOrganizerInput = () => import("./SplitterOrganizerInput");
const FolderChangeMap = () => import("./FolderChangeMap");
const CtoChange = () => import("./CtoChange");
const CeoChange = () => import("./CeoChange");
const OtdrModule = () => import("./OtdrModule");
const ClientWithoutConnection = () => import("./ClientWithoutConnection");
const ModelPreview = () => import("./ModelPreview/ModelPreview.vue");
const Deactivation = () => import("./Deactivation");
const ConnectionOrganizer = () => import("./ConnectionOrganizer");
const ConnectionLabelChange = () => import("./ConnectionLabelChange");
const UsersFolder = () => import("./UsersFolder");

const ClientWithoutOltAssociation = () =>
  import("./ClientWithoutOltAssociation");
const KmlUpload = () => import("../../importer/KmlUpload");
const KmlExtractList = () => import("../../importer/KmlExtractList");
const ConnectionReport = () => import("./ConnectionReport");

const Layers = () => import("./Layers");
// import Layers from "./Layers";

import Pop from "./Pop";
import Amendment from "./Amendment";
import Client from "./Client";
import Cto from "./Cto";
import Cable from "./Cable";
import Pole from "./Pole";
import Polygon from "./Polygon";
import TechnicalReserve from "./TechnicalReserve";
import CommentMap from "./CommentMap";
import MultiSelect from "./MultiSelect";
import VirtualCircuit from "./VirtualCircuit";
import Cordoalha from "./Cordoalha";
import MaintenancePoint from "./MaintenancePoint";
import VerticalCondominium from "./VerticalCondominium";

const DioInput = () => import("./DioInput");
// import DioInput from "./DioInput";

const SplitterInput = () => import("./SplitterInput");
// import SplitterInput from "./SplitterInput";

const SwitchInput  = () => import("./SwitchInput");

const ContactInput = () => import("./ContactInput");

const BlockInput = () => import("./BlockInput");

const OltInput = () => import("./OltInput");
// import OltInput from "./OltInput";

const StreetView = () => import("./StreetView");
// import StreetView from "./StreetView";

const Conexao = () => import("./Conexao");
// import Conexao from "./Conexao";

const Diagram = () => import("./Diagram");
// import Diagram from "./Diagram";

const DamagedFiber = () => import("./DamagedFiber");
// import DamagedFiber from "./DamagedFiber";

const DamagedFiberList = () => import("./DamagedFiberList");
// import DamagedFiberList from "./DamagedFiberList";

const OltBoardInput = () => import("./OltBoardInput");
// import OltBoardInput from "./OltBoardInput";

const EquipmentLimit = () => import("./EquipmentLimit");
// import EquipmentLimit from "./EquipmentLimit";

const Building = () => import("./Building");
// import Building from "./Building";

// const Movie = () => import("./Movie");
// import Movie from "./Movie";
const HardwareKitChange = () => import("../../map/popup/HardwareKitChange.vue");

import { openPopupBus } from "../../../components/MessageBus";
import router from "@/router";

const isMobile = window.orientation > -1;

const openPopup = (
  component,
  id,
  geometry,
  listItens,
  equipmentId,
  entity,
  heightFull,
  isProject,
  extra,
  folderIdMap,
  leftMenu,
  middle,
  cb
) => {
  let componentId = Math.random();

  let varFullScreen = false;

  if (isMobile || heightFull) {
    varFullScreen = true;
  }

  openPopupBus({
    component: component,
    props: {
      id,
      geometry,
      listItens,
      equipmentId,
      entity,
      isProject,
      fullScreen: varFullScreen,
      middle,
      componentId,
      extra,
      folderIdMap,
      leftMenu,
      featureType: entity && entity.type ? entity.type : null,
      cb
    }
  });
};

// export const openPopupTicket = () => {
//     openPopup(TicketList, null, null, null, true, null, true)
// }

// export const openPopupTicketInput = () => {
//     openPopup(TicketInput, null, null, null, true)
// }

// export const openPopupProject = () => {
//   openPopup(ProjectList, null, null, null, true);
// };

export const openPopupLayer = layerList => {
  openPopup(
    Layers,
    null,
    null,
    layerList,
    true,
    null,
    null,
    null,
    null,
    null,
    true
  );
};

export const openMultiSelect = (layerList, isMove) => {
  openPopup(MultiSelect, null, null, layerList, true, null, null, null, isMove);
};

// export const openDoorReserved = () => {
//     openPopup(Activation, null, null, null, true, null, true)
// }

export const openPopupDamaged = () => {
  openPopup(DamagedFiberList, null, null, null, true);
};

export const openPopupMessage = () => {
  openPopup(MessageList, null, null, null, true, null, true);
};

export const openPrintMap = () => {
  openPopup(PrintMap, null, null, null, null);
};

export const openPopupFolder = () => {
  openPopup(FolderList, null, null, null, true);
};

export const openPopupUsersFolder = (id) => {
  openPopup(UsersFolder, id);
}

export const openPopupClientWithoutConnection = () => {
  openPopup(ClientWithoutConnection);
};

export const openPopupDeactivation = () => {
  openPopup(Deactivation, null, null, null, null, null, true);
};

export const openPopupModelPreview = () => {
  openPopup(ModelPreview, null, null, null, null, null, false, null, null, null, null, true);
};

// export const openVideoList = () => {
//     openPopup(VideoList, null, null, null, true)
// }

export const openPopupClientWithoutOltAssociation = () => {
  openPopup(ClientWithoutOltAssociation);
};

export const openPopupOltInput = (id, equipmentId, cb) => {
  openPopup(OltInput, id, null, null, equipmentId, null, null, null, null, null, null, null, cb);
};

export const openKmlInput = (geometry) => {
  router.push({ 
    name: 'kmlExtract', 
    params: { geometry: geometry } 
  });
};

// export const openPopupImporter = () => {
//     openPopup(Importer, null, null, null, null, null, true)
// }

export const openSubPopup = (
  popup,
  entityId,
  equipmentId,
  entity,
  projectId,
  extra,
  folderIdMap,
  url,
  cb
) => {
  let component;

  let heightFull = false;
  let middle = false;
  // console.log('openSUBPopup EXTRA', entityId, extra)

  switch (popup) {
    case 1:
      component = DioInput;
      break;
    case 2:
      component = SplitterInput;
      break;
    case 3:
      component = Conexao;
      // width = true
      heightFull = true;

      break;
    case 4:
      component = Diagram;
      // width = true
      //used to identify if the diagram is resumed
      entityId = true;
      heightFull = true;
      break;
    case 5:
      component = Diagram;
      // width = true
      heightFull = true;
      break;
    case 6:
      component = Viability;
      middle = true;
      break;
    case 7:
      component = ConnectionReport;
      break;
    case 8:
      component = Image;
      break;
    case 9:
      component = ImageUpload;
      break;
    /*case 10: removido utilizando uma nova lib que faz o papael de exibir as imagens
            component = ImageFull
            break*/
    case 11:
      component = Comment;
      break;
    case 12:
      component = CommentInput;
      break;
    // case 13:
    //     component = ProjectInput
    //     break
    // case 14:
    //     component = ProjectReport
    //     break
    case 15:
      component = OltInput;
      break;
    // case 16:
    //     component = DoorReserve
    //     break
    case 17:
      component = DamagedFiber;
      break;
    // case 18:
    //   component = FolderCapexReport
    //   break
    case 19:
      component = OltBoardInput;
      break;
    case 20:
      component = FolderInput;
      break;
    case 21:
      component = FolderReport;
      middle = true;
      break;
    case 22:
      component = FolderEquipamentsReport;
      break;
    case 23:
      component = FolderUserList;
      break;
    case 24:
      component = FolderChange;
      break;
    case 25:
      component = CableClient;
      break;
    case 26:
      component = SplitterOrganizerInput;
      break;
    case 27:
      component = CtoChange;
      break;
    case 28:
      component = OtdrModule;
      break;
    case 29:
      component = Building;
      heightFull = true;
      break;
    case 30:
      component = ConnectionOrganizer;
      break;
    case 31:
      component = ConnectionLabelChange;
      break;
    // case 32:
    //   component = Movie;
    //   break;
    case 33:
      component = KmlUpload;
      break;
    case 34:
      component = SwitchInput;
      break;
    case 35:
      component = ContactInput;
      break;
    case 36:
      component = BlockInput;
      break;
    case 37:
    component = KmlExtractList;
      break;
    case 38:
      component = CeoChange;
      break;
    case 39:
      component = HardwareKitChange;
      break;
  }

  if (component) {
    openPopup(
      component,
      entityId,
      null,
      null,
      equipmentId,
      entity,
      heightFull,
      projectId,
      extra,
      folderIdMap,
      url,
      middle,
      cb
    );
  }
};

export const openPopupMap = (entity, geometry, isProject, extra) => {
  let component;

  switch (entity.type) {
    case "P":
      component = Pop;
      break;

    case "C":
      component = Amendment;
      break;

    case "S":
      component = Client;
      break;

    case "O":
      component = Cto;
      break;

    case "CD":
    case "CC":
      component = Cable;
      break;
    case "U":
      component = Polygon;
      break;
    case "T":
      component = TechnicalReserve;
      break;
    case "E":
      component = Pole;
      break;
    case "G":
      component = StreetView;
      break;
    case "SP":
      component = CommentMap;
      break;
    case "CV":
      component = VirtualCircuit;
      break;
    case "CL":
      component = Cordoalha;
      break;
    case "MP":
      component = MaintenancePoint;
      break;
    case "MV":
      component = FolderChangeMap;
      break;
    case "V":
      component = VerticalCondominium;
      break;
    case "EL":
      component = EquipmentLimit;
      break;
  }

  if (component) {
    openPopup(
      component,
      entity.id,
      geometry,
      null,
      null,
      entity,
      null,
      isProject,
      extra,
      entity.folderId
    );
  }
};
